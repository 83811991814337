<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div class="tableBox">
      <el-table :data="tableData" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        border style="width: 100%">
        <el-table-column type="index" :index="indexMethod" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="orderId" label="订单ID" textAlign="left" width="240">
          <template slot-scope="scope">
            <div style="text-align: left">订单号：{{ scope.row.orderId }}</div>
            <div style="text-align: left">
              创建时间：{{ scope.row.createTime | formatTime("YMDHMS") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="spList" label="商品详情" width="250px">
          <template slot-scope="scope">
            <div class="spList">
              <div v-for="item in scope.row.spList" :key="item.id" class="box">
                <img :src="item.coverImg.split(';')[0]" alt="" />
                <div class="info">
                  <div class="name">{{ item.name }}</div>
                  <div class="priceBox">
                    X{{ item.num }}
                    <span>￥{{ parseFloat(item.price) / 100 }}</span>
                  </div>
                  <div class="specs" v-if="item.specs">{{ item.specs }}</div>
                  <div class="priceBox">
                    运费
                    <span>￥{{ parseFloat(item.kdf) / 100 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="account" label="订单总价" width="80px">
          <template slot-scope="scope">
            <span style="color: #db2c2c">￥{{ parseFloat(scope.row.account) / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="addressFullName" label="收货人信息" width="180px">
          <template slot-scope="scope">
            <div style="text-align: left">
              收货人：{{ scope.row.addressFullName }}
            </div>
            <div style="text-align: left">
              手机号：{{ scope.row.addressPhoneNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="订单地址" width="180px">
          <template slot-scope="scope">
            {{ scope.row.address }}
            <span v-if="scope.row.addressType == '0'">【自提】</span>
          </template>
        </el-table-column>
        <el-table-column prop="userInfo" label="下单人信息" width="180px">
          <template slot-scope="scope">
            <div style="text-align: left">
              微信名：{{ scope.row.userInfo.nickName }}
            </div>
            <div style="text-align: left">
              真实名：{{ scope.row.userInfo.realName }}
            </div>
            <div style="text-align: left">
              手机号：{{ scope.row.userInfo.phone }}
            </div>
            <div style="text-align: left">
              订单类型：{{
      scope.row.addressType == "0"
        ? "自提点"
        : scope.row.addressType == "1"
          ? "快递"
          : ""
    }}
            </div>
            <div style="text-align: left" v-if="scope.row.addressType == '0'">
              取货码：{{ scope.row.qhm }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" width="80">
          <template slot-scope="scope">{{
      scope.row.orderStatus == "0"
        ? "待付款"
        : scope.row.orderStatus == "1"
          ? "已付款"
          : scope.row.orderStatus == "2"
            ? "已发货"
            : "已完成"
    }}</template>
        </el-table-column>
        <el-table-column prop="ydh" label="运单号" width="150px"></el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop label="操作" width="140">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="fahuo(scope.row.orderId, scope.row.ydh)"
              v-if="scope.row.orderStatus == '1'">发货</el-button>
            <el-button type="text" size="small" @click="fahuo(scope.row.orderId, scope.row.ydh)"
              v-if="scope.row.orderStatus == '2'">修改运单号</el-button>
            <el-button type="text" size="small" @click="compeleteOrder(scope.row.orderId)"
              v-if="scope.row.orderStatus == '2'">完成</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <OrderDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import OrderDialog from "./components/dialog.vue";
import config from "@/assets/api/config.js";
export default {
  components: { searchBar, OrderDialog },
  data() {
    return {
      config,
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "orderId",
          attrs: {
            placeholder: "订单号",
          },
        },
        {
          label: "订单状态",
          type: "el-select",
          fieldName: "orderStatus",
          attrs: {
            placeholder: "订单状态",
          },
          data: [
            {
              label: '全部',
              value: '',
            }, {
              label: '待付款',
              value: '0',
            }, {
              label: '已付款',
              value: '1',
            }, {
              label: '已发货',
              value: '2',
            }, {
              label: '已完成',
              value: '3',
            }
          ]
        },
        {
          label: "订单类型",
          type: "el-select",
          fieldName: "addressType",
          attrs: {
            placeholder: "订单类型",
          },
          data: [
            {
              label: '全部',
              value: '',
            }, {
              label: '自提',
              value: '0',
            }, {
              label: '快递',
              value: '1',
            }
          ]
        }
      ],
    };
  },
  methods: {
    fahuo(id, ydh) {
      //发货
      this.$refs.dialog.form.id = id;
      this.$refs.dialog.form.ydh = ydh;
      this.$refs.dialog.visible = true;
    },
    compeleteOrder(id) {
      //已完成订单
      this.$confirm("确定已完成？").then(() => {
        this.getAjax(
          "api/order/compelete",
          {
            id,
          },
          "post"
        ).then((res) => {
          console.log(res);
          this.$message.success("操作成功");
          this.initPage();
        });
      });
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索

      this.page.page = 1;
      this.params = opt;
      console.log(this.page);
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/order/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;

  .leftBox {
    width: 10%;
    min-width: 130px;
    height: 100%;
    background: #545c64;
  }

  .rightBox {
    flex: 1;
    padding: 20px;

    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }

  .spList {
    .box {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: auto;
        display: inline-block;
      }

      .info {
        flex: 1;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 12px;
        }

        .priceBox {
          display: flex;
          align-items: center;

          span {
            margin-left: 20px;
            color: #db2c2c;
          }
        }

        .specs {
          display: inline-block;
          height: 26px;
          line-height: 26px;
          padding: 0 10px;
          border: 1px solid #fe560a;
          color: #fe560a;
          background-color: #f8f8f8;
        }
      }
    }
  }
}
</style>
